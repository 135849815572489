import styled, { css } from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing,
  zindex
} from 'theme';

export const StyledSideNav = styled.div`
  background-color: ${color('midnight_800')};
  color: ${color('text_default_inverse')};
  height: calc(100vh - ${spacing('xl4')});
  width: ${container('portalSidebar')};

  &.is-legacy {
    height: 100vh;
  }

  @media (max-width: ${screen('lgMax')}) {
    position: fixed !important;
    left: -${container('portalSidebar')};
    top: 0;
    z-index: ${zindex('portalSidebar')};
    transition: left 0.3s;

    @supports (-webkit-touch-callout: none) {
      height: auto;
    }

    &::before {
      content: '';
      background-color: ${color('midnight_900')};
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: -200px;
    }

    &.is-active {
      left: 0 !important;
    }
  }

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;

    /* Forces Safari mobile to not take into account the navbar */
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
`;

export const StyledLogo = styled.div`
  flex-shrink: 0;
  margin-top: -3px;

  @media (min-width: ${screen('lg')}) {
    margin-top: 0;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  gap: ${spacing('md')};
  align-items: center;
  padding-top: ${spacing('md')};
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};

  @media (min-width: ${screen('lg')}) {
    padding-top: ${spacing('xl')};
  }
`;

export const StyledClose = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: ${fontSize('xl2')};
  color: ${color('midnight_300')};
  width: ${spacing('lg')};

  @media (min-width: ${screen('lg')}) {
    display: none;
  }
`;

export const StyledContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: ${spacing('lg')} ${spacing('md')};

  &::-webkit-scrollbar {
    height: 12px;
    width: ${spacing('xs2')};
    background: ${color('midnight_400')};
  }

  &::-webkit-scrollbar-thumb {
    background: ${color('midnight_600')};
  }

  &::-webkit-scrollbar-corner {
    background: ${color('midnight_800')};
  }
`;

export const StyledSection = styled.nav`
  padding-bottom: ${spacing('lg')};
`;

export const StyledTitle = styled.h6`
  margin-top: 0;
  margin-bottom: ${spacing('xs')};
  color: ${color('text_subtle_inverse')};
  font-size: ${fontSize('xs')};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: ${fontWeight('extraBold')};
  padding-bottom: ${spacing('sm')};
  border-bottom: 1px solid ${color('midnight_700')};
`;

export const StyledNavItem = styled.a<{
  active?: boolean;
  isHighlighted?: boolean;
}>`
  -webkit-appearance: none;
  cursor: pointer;
  padding: ${spacing('xs')} 0;
  background: unset;
  border: unset;
  color: inherit;
  font-family: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  text-align: inherit;

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
  }

  strong {
    font-weight: ${fontWeight('extraBold')};
  }

  &:hover {
    color: ${color('link_inverse_hover')};
  }

  &:active {
    color: ${color('link_inverse_active')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${color('text_active_dark')};
    `}

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${color('link_inverse')};
    `}
`;

export const StyledBeta = styled.span`
  color: ${color('notice_500')};
  font-size: ${fontSize('xs2')};
  font-weight: ${fontWeight('extraBold')};
  line-height: 1.2;
  text-transform: uppercase;
`;

export const StyledBlank = styled.span`
  color: ${color('midnight_500')};
  font-size: ${fontSize('xs2')};

  svg {
    height: ${fontSize('xs2')};
  }
`;

export const StyledProgress = styled.div<{ width?: number }>`
  background-color: ${color('midnight_700')};
  height: ${spacing('xs2')};
  border-radius: ${radius('sm')};
  margin-left: ${spacing('lg')};
  position: relative;
  transition: width 0.2s;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${color('emerald_500')};
    border-radius: ${radius('sm')};
    width: 0;
    height: ${spacing('xs2')};
    ${({ width }) =>
      width &&
      css`
        width: ${`${width}%`};
      `}
  }
`;

export const StyledSubNav = styled.div`
  padding-left: ${spacing('lg')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  &.is-active {
    margin-bottom: ${spacing('sm')};
  }

  ${StyledNavItem} {
    font-size: ${fontSize('xs')};
  }

  ${StyledProgress} {
    margin-left: 0;
    margin-bottom: ${spacing('xs')};
    max-width: 9.5rem;
  }
`;

export const StyledFooter = styled.div`
  flex-shrink: 0;
  margin-top: auto;
`;

export const StyledTopbar = styled.div`
  padding: ${spacing('lg')};
  height: ${spacing('xl4')};
  background-color: ${color('white')};
  display: flex;
  align-items: center;
  gap: ${spacing('md')};

  @media (min-width: ${screen('lg')}) {
    display: none;
  }
`;

export const StyledTopbarNavTrigger = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  padding-top: 0.35rem;
  font-size: ${fontSize('xl')};
  color: ${color('midnight_300')};
  width: ${spacing('lg')};
`;
