import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useMemo, useRef, useState } from 'react';
import { StyledNavItem, StyledProgress, StyledSubNav } from './SideNav.styled';
import { NavItem } from './SideNav.types';

export const SideSubNav: React.FC<NavItem> = ({
  title,
  subNavItems,
  totalFields,
  completedFields,
  totalItems
}) => {
  const { t } = useI18n('portal-layout');
  const { asPath } = useRouter();

  const shouldBeOpenedByDefault = useMemo(() => {
    return subNavItems?.some((navItem) => navItem.href === asPath) || false;
  }, [subNavItems, asPath]);

  const [activeState, setActiveState] = useState(
    shouldBeOpenedByDefault ? 'is-active' : ''
  );
  const [setHeight, setHeightState] = useState(
    shouldBeOpenedByDefault ? '500px' : '0px'
  );

  const content = useRef<HTMLDivElement>(null);

  function toggleAccordion(): void {
    setActiveState((prev) => (prev === '' ? 'is-active' : ''));

    if (content !== null) {
      if (content.current !== null) {
        setHeightState(
          activeState === 'is-active'
            ? '0px'
            : `${content.current.scrollHeight}px`
        );
      }
    }
  }

  return (
    <>
      <StyledNavItem
        as="button"
        className={`${activeState}`}
        onClick={toggleAccordion}
      >
        {activeState != 'is-active' && <FontAwesomeIcon icon={faCaretRight} />}
        {activeState === 'is-active' && <FontAwesomeIcon icon={faCaretDown} />}
        <span>
          {title}{' '}
          {Boolean(totalItems && totalItems >= 0) && (
            <strong>({totalItems})</strong>
          )}
        </span>
        {totalFields && completedFields && (
          <StyledProgress
            width={(completedFields * 100) / totalFields}
          ></StyledProgress>
        )}
      </StyledNavItem>
      <StyledSubNav
        ref={content}
        className={`${activeState}`}
        style={{ maxHeight: `${setHeight}` }}
      >
        {subNavItems?.map((subNavItem, index) => (
          <Fragment key={`subnav_${index}`}>
            <Link href={subNavItem.href} passHref>
              <StyledNavItem
                active={subNavItem.href === asPath}
                onClick={subNavItem.onClick}
                target={subNavItem.blank ? '_blank' : undefined}
              >
                <span>
                  {t(subNavItem.title)}

                  {Boolean(
                    subNavItem.totalItems && subNavItem.totalItems >= 0
                  ) && <strong>({subNavItem.totalItems})</strong>}
                </span>
              </StyledNavItem>
            </Link>
            {subNavItem.totalFields && subNavItem.completedFields && (
              <StyledProgress
                width={
                  (subNavItem.completedFields * 100) / subNavItem.totalFields
                }
              ></StyledProgress>
            )}
          </Fragment>
        ))}
      </StyledSubNav>
    </>
  );
};
