import styled, { css } from 'styled-components';
import { radius, spacing } from '../../theme';

const sizeVariants: {
  [key: string]: {
    width: number | ((key: any) => any);
    height: number | ((key: any) => any);
  };
} = {
  sm: {
    width: spacing('xl'),
    height: spacing('xl')
  },
  lg: {
    width: spacing('xl4'),
    height: spacing('xl4')
  }
};

export const StyledAvatar = styled.div<{ sizes?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  border-radius: ${radius('full')};
  transition: 0.3s;

  img {
    display: block;
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  ${({ sizes }) =>
    sizes &&
    sizeVariants[sizes] &&
    css`
      width: ${sizeVariants[sizes].width};
      height: ${sizeVariants[sizes].height};
    `}
`;
