import { EventTracking } from 'services/service.event-tracking.types';

// Nav
// ----------------------------------------------------------------
export const PORTAL_SIDENAV_EVENT_NAV_JOB_OFFERS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - job offers'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_MANAGE_ALERTS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - alert management'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_JOB_SEARCH: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - job search'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PRESENTATION: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - presentation'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_CONTACT_INFORMATION: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - contact information'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PROFESSIONAL_EXPERIENCE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - professional experience'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_EDUCATION_AND_KNOWLEDGE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - education and knowledge'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_LANGUAGES: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - languages'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_VIEW_PROFILE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - view profile'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_IMPORT_FROM_LINKEDIN: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - import from linkedin'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PRIVACY_AND_ACCESS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - privacy and access'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_TRACK_JOB_APPLICATIONS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - track job applications'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_SALARY_COMPASS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - salary compass'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_TECH_ROLES_GLOSSARY: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - tech roles glossary'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_JOB_IDEAL_JOB: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - ideal job'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_JOB_SALARY: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - salary expectations and location'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_AVAILABILITY: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - availability and contact'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PERSONAL_INFO: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - personal information'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PROFESSIONAL_INFO: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - professional information'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_TECHS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - tools and technologies'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_OTHER_INFO: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - other information'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_JOB_POSITIONS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - job roles'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PROJECTS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - projects'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_HIGHLIGHTS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - career highlights'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_EDUCATION: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - education'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_CERTIFICATIONS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'subcategory - certification'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_BOOKMARKS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - bookmarks'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_LINKEDIN_IMPORT: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - import from linkedin'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_PROFILE_ACCESS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - manage profile access'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_JOB_APPLICATIONS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - track job applications'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_DOWNLOAD_PROFILE_PDF: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - download profile pdf'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_ROLES_GUIDE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - tech roles glossary'
  }
};

export const PORTAL_SIDENAV_EVENT_NAV_SALARY_BENCHMARK: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral menu',
    objectName: 'maincategory - salary benchmark'
  }
};

// BottomBar
// ----------------------------------------------------------------
export const PORTAL_SIDENAV_EVENT_BOTTOMBAR_HOME: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral bottom bar',
    objectName: 'icon - home portal'
  }
};

export const PORTAL_SIDENAV_EVENT_BOTTOMBAR_SETTINGS: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral bottom bar',
    objectName: 'icon - settings portal'
  }
};

export const PORTAL_SIDENAV_EVENT_BOTTOMBAR_LOGOUT: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral bottom bar',
    objectName: 'icon - close session'
  }
};

// BottomBox
// ----------------------------------------------------------------
export const PORTAL_SIDENAV_EVENT_BOTTOMBOX_SETTINGS_AVAILABILITY: EventTracking =
  {
    type: 'Portal - UI Interaction',
    properties: {
      object: 'lateral bottom box',
      objectName: 'icon - settings availability'
    }
  };

export const PORTAL_SIDENAV_EVENT_BOTTOMBOX_VIEW_PROFILE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral bottom box',
    objectName: 'icon - view profile'
  }
};

export const PORTAL_SIDENAV_EVENT_BOTTOMBOX_SETTINGS_PROFILE: EventTracking = {
  type: 'Portal - UI Interaction',
  properties: {
    object: 'lateral bottom box',
    objectName: 'icon - settings profile'
  }
};
