import { StyledAvatar } from './Avatar.styled';

export type AvatarProps = {
  avatarImg?: string;
  avatarAlt: string;
  className?: string;
  sizes?: string;
};

const Avatar = ({ avatarImg, avatarAlt, className, sizes }: AvatarProps) => {
  return (
    <>
      {avatarImg && (
        <StyledAvatar className={className} sizes={sizes}>
          <img
            width={40}
            height={40}
            src={decodeURI(avatarImg)}
            alt={avatarAlt}
          />
        </StyledAvatar>
      )}
    </>
  );
};

export default Avatar;
